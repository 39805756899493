// vue babel plugin doesn't support pragma replacement
import { mdx } from '@mdx-js/vue'
let h;
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 id="table-of-contents">Table of contents:</h4>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#introduction"
        }}>{`Introduction`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#lighthouse-and-the-core-web-vitals"
        }}>{`Lighthouse and the Core Web Vitals`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#why-lighthouse-is-not-enough"
        }}>{`Why Lighthouse is not enough?`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#conclusion"
        }}>{`Conclusion`}</a></li>
    </ol>
    <h2 id="introduction">
  Introduction
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`Lighthouse is an open-source tool provided by Google, embedded directly in the Chrome browser. The purpose
of this tool is to perform audits on any website and provide tons of insights and recommendations, helping
you deliver a great user experience (UX).`}</p>
    <p>{`From my point of view, Google has created the tool everyone has been waiting for. Some of you reading this
post might have been asked to perform a website audit, explain why one site is better than another, or just
showcase how reliable your work is. You will definitely enjoy using Lighthouse.`}</p>
    <p>{`Lighthouse also gives you a glimpse into how Google ranks your website when a user searches for similar
content on the Web, based on the score your site achieves.`}</p>
    <p>{`As of the time I’m writing this post, you can use Lighthouse in the following ways:`}</p>
    <ul>
      <li parentName="ul">{`The easiest way is to go to `}<a parentName="li" {...{
          "href": "https://web.dev/measure"
        }}>{`web.dev/measure`}</a>{` and enter any URL you want to audit.`}</li>
    </ul>
    <img style={{
      width: '100%'
    }} src={require('@/assets/img/post/web_dev_mesure_link.png')} loading="lazy" alt="Wally's blog pwa" description="Web dev measure website" />
    <hr></hr>
    <ul>
      <li parentName="ul">{`In Chrome DevTools, go to the website you want to test, open DevTools, find the Lighthouse tab, and
generate a report.`}</li>
    </ul>
    <hr></hr>
    <img style={{
      width: '100%'
    }} src={require('@/assets/img/post/chrome_web_tools_lighthouse.png')} loading="lazy" alt="Wally's blog pwa" description="Lighthouse chrome devtools" />
    <ul>
      <li parentName="ul">{`Add Lighthouse to your project as a Node module and use it directly in your development or delivery
workflow. To monitor performance regressions, you can use Lighthouse CI and track metrics internally
for improvement. Check out the `}<a parentName="li" {...{
          "href": "https://github.com/GoogleChrome/lighthouse-ci"
        }}>{`"Getting Started" guide on GitHub`}</a>{`.`}</li>
    </ul>
    <h2 id="lighthouse-and-the-core-web-vitals">
  Lighthouse and the Core Web Vitals
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`Google’s goal, as usual, is to make the Web a better place.`}</p>
    <p>{`The best-ranked websites after a Google search are those that offer the best user experience. Although
some sites pay Google for top rankings, these are clearly tagged as paid. Meanwhile, sites with great
content and UX are considered `}<strong parentName="p">{`good content providers`}</strong>{` by Google and pushed to the top based on their
`}<inlineCode parentName="p">{`Core Web Vitals score`}</inlineCode>{`.`}</p>
    <p>{`Initially, Lighthouse aimed to "demystify" web performance for both tech and non-tech audiences. The problem
was that Google announced new rules, with a deadline, for applying these metrics to its search ranking
algorithm.`}</p>
    <p>{`During the global open Beta, Lighthouse proved robust and invaluable for IT departments. The metrics it
provided were so comprehensive that no one could hide anymore. Your site or app was either performing well
or it wasn't, but improvement paths remained unclear for some companies, especially those with massive
legacy systems.`}</p>
    <p>{`This is why Google simplified things by introducing Core Web Vitals—a subset of the original metrics.
You can read more at `}<a parentName="p" {...{
        "href": "https://web.dev/vitals"
      }}>{`web.dev/vitals`}</a>{`. The three most critical metrics are:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`LCP (Largest Contentful Paint)`}</strong>{`: Measures loading performance.`}</li>
      <li parentName="ul"><strong parentName="li">{`FID (First Input Delay)`}</strong>{`: Measures page interactivity.`}</li>
      <li parentName="ul"><strong parentName="li">{`CLS (Cumulative Layout Shift)`}</strong>{`: Measures visual stability.`}</li>
    </ul>
    <img style={{
      width: '100%'
    }} src={require('@/assets/img/post/core_web_vitals_metrics.png')} loading="lazy" alt="Core web Vitals" description="Core web Vitals" />
    <p>{`These metrics are essential to ensuring your users have a positive experience. If any of these are
degraded, users may turn to other sources where the UX is more pleasant.`}</p>
    <h2 id="why-lighthouse-is-not-enough">
  Why Lighthouse is not enough?
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`If you’re new to Lighthouse, you might think it’s the silver bullet you’ve been waiting for—just run it
and get all the insights you need. However, Lighthouse will only provide insights for a successful user
journey, not everything.`}</p>
    <p>{`Google sets the bar high, but depending on your product, customer habits, etc., you may need more detailed
metrics. For example, if most users access your content via ethernet or from a specific region, you’ll need
other tools to tailor your optimization path.`}</p>
    <p>{`Tools like `}<inlineCode parentName="p">{`Sentry`}</inlineCode>{` or `}<inlineCode parentName="p">{`Rollbar`}</inlineCode>{` help monitor real-time issues, and `}<strong parentName="p">{`feature flag`}</strong>{` tools like `}<inlineCode parentName="p">{`LaunchDarkly`}</inlineCode>{` let you
experiment with features safely and track feature toggling data.`}</p>
    <p>{`Lighthouse also falls short when it comes to issues related to your infrastructure's scalability. If your
application serves multiple regions, it must run on an architecture that ensures fast response times in
each region.`}</p>
    <h2 id="conclusion">
  Conclusion
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`As explained above, there is no silver bullet. Any issue your users encounter should be monitored, and many
tools can help you focus on known problems. This avoids wasting time reinventing the wheel, so you can
concentrate on what truly matters for your users.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;

export default {
  name: 'Mdx',
  inject: {
    $mdxComponents: {
      default: () => () => ({})
    }
  },
  computed: {
    components() {
      return this.$mdxComponents()
    }
  },
  render(createElement) {
    h = mdx.bind({ createElement, components: this.components })
    return MDXContent({ components: this.components })
  }
}
   